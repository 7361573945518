import { PageProps } from 'gatsby';
import { FC } from 'react';
import { Helmet } from 'react-helmet';

import { useTranslateData } from '../hooks';
import { Tariffs } from '../sections';

type ITariffsNavigation = {
  metaTitle: string
  metaDescription: string
  metaKeywords: string
  url: string;
};

const TariffsPage: FC<PageProps> = () => {
  const { metaTitle, metaDescription, metaKeywords } = useTranslateData<ITariffsNavigation>(
    'navigationByKey.tariffs'
  ) as ITariffsNavigation;
  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />
        <link rel="icon" type="image/png" href="/favicon.png"></link>
        <link rel="shortcut icon" href="/favicon.ico" type="image/vnd.microsoft.icon" />
        <style type="text/css">
          {`
            .bg-page {
              background-color: #FDFCFC;
            }
          `}
        </style>
      </Helmet>
      <Tariffs />
    </>
  );
};

export default TariffsPage;
